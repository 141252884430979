const $ = require('jquery')

function PagetopToggle(event)
{
  var scrVal  = $(window).scrollTop()
    , pagetop = $('.pagetop__link')

  if( scrVal > 0 ) { pagetop.addClass('active') }
  else { pagetop.removeClass('active') }
}

module.exports = PagetopToggle
