const $ = require('jquery')

function Util(){
  this.is_sm = false

  $(window).on('resize', this.checkBreakpoint.bind(this))
  this.checkBreakpoint()
}

Util.prototype.checkBreakpoint = function()
{
  var breakpoint = 768
    , win_w      = window.innerWidth

  this.is_sm = breakpoint > win_w
}

module.exports = Util
