const jQuery = require('jquery')
const $ = jQuery

const Util          = require('./Util')

const Carousel      = require('./Carousel')
const TabContent    = require('./TabContent')
const PagetopToggle = require('./PagetopToggle')
const GlobalToggle  = require('./GlobalToggle')

jQuery(function(){
  var pagetop = $('.pagetop__link')
    , util = new Util
    , globalToggle = new GlobalToggle
    , is_sm = null

  pagetop.on('click', scrollTop)

  $(window).on('scroll load', PagetopToggle)

  var tabContent = new TabContent()
  var carousel = new Carousel('.mainvisual__slider')

  $(window).on('resize', init.bind(this))
  init()

  function init()
  {
    if( is_sm === util.is_sm ) { return }

    if( util.is_sm ) {
      $(window).off('scroll.global')
      $('.globalnav__toggle').on('click.global', globalToggle.onClickSM.bind(globalToggle))
    } else {
      $('.globalnav__toggle').off('click.global')
      $(window).on('scroll.global', globalToggle.onClickLG.bind(globalToggle))
    }
    is_sm = util.is_sm
  }
})

function scrollTop(event) {
  if (event.preventDefault) {
    event.preventDefault()
  }
  $('html, body').stop().animate({
    scrollTop: 0
  })

  return false
}
