const jQuery = require('jquery')
const $ = jQuery

const slick = require('slick-carousel')

function Carousel(elem){
  this.slider = null

  this.init(elem)
}

Carousel.prototype.init = function(elem) {
  var $elem = $(elem)

  if( !$elem.get(0) ) { return }

  this.slider = $elem.slick({
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5 * 1000,
    speed: 5000,
    pauseOnHover: false
  })

  $(this.slider.slick('getSlick').$slides[0]).addClass('active')

  this.slider.on('beforeChange', this.onBeforeChange)
  this.slider.on('afterChange', this.onAfterChange)
}

Carousel.prototype.onBeforeChange = function(elem, slick, currentSlide, nextSlide )
{
  $(slick.$slides[nextSlide]).addClass('active')
}

Carousel.prototype.onAfterChange = function(elem, slick, currentSlide)
{
  var count = slick.$slides.length
    , lastSlide = slick.$slides[count - 1]

  if( currentSlide >= 1 ) {
    $(slick.$slides[currentSlide - 1])
      .removeClass('active')
  } else {
    $(lastSlide)
      .removeClass('active')
  }
}

module.exports = Carousel
