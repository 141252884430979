const $ = require('jquery')

function TabContent(){
  this.tabs     = $('[class^="section__tab--item"]')
  this.contents = $('.section__tab--content')

  this.className = 'current'

  if( !this.tabs.get(0) || !this.contents.get(0) ) { return }

  this.tabs.first().find('.section__tab--link').addClass(this.className)
  this.contents.first().addClass(this.className)

  this.clickEvent()
};

TabContent.prototype.clickEvent = function(){
  this.tabs.on('click', function(event) {
    event.preventDefault();

    var $elem = $(event.currentTarget)
      , index = this.tabs.index($elem);

    this.tabs.find('.section__tab--link').removeClass(this.className);
    $elem.find('.section__tab--link').removeClass(this.className);

    this.contents.removeClass(this.className);
    this.contents.get(index) ? $(this.contents.get(index)).add($elem.find('.section__tab--link')).addClass(this.className) : null;

    return false;
  }.bind(this));
};

module.exports = TabContent;
