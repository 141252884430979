const $ = require('jquery')

function GlobalToggle(event)
{
  this.className = 'active'
}

GlobalToggle.prototype.onClickLG = function(event)
{
  var scrVal  = $(window).scrollTop()
    , content = $('.content')
    , navTop  = content.get(0) ? content.offset().top : 0
    , nav     = $('.globalnav')

  if( scrVal < navTop ) { nav.removeClass(this.className) }
  else { nav.addClass(this.className) }
}

GlobalToggle.prototype.onClickSM = function(event)
{
  var button = $(event.currentTarget)
    , nav    = $('.globalnav')
    , target = nav.add(button).add('body')

  event.preventDefault()
  
  if( button.hasClass(this.className) ) {
    target.removeClass(this.className)
  } else {
    target.addClass(this.className)
  }
}

module.exports = GlobalToggle
